import * as React from "react";
import Header from "../components/Header/Header";
import "./../sass/main.scss";
import Footer from "../components/Footer/Footer";

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";

SwiperCore.use([Autoplay, Pagination, Navigation]);
let EN_URL = process.env.EN_URL ? process.env.EN_URL : "/";
let FR_URL = process.env.FR_URL ? process.env.FR_URL : "/";
const NotFoundPage = () => {
  let isFrench = process.env.LANG === "FR";
  let isInternal = process.env.INT_EXT === "internal";
  return (
    <>
      <Header
        title={
          isFrench
            ? "Carrières chez RONA inc., et Rona"
            : "Careers at RONA inc., and Rona"
        }
        description={
          isFrench
            ? "Vous cherchez à entamer un nouveau chapitre avec RONA inc.? Consultez nos offres d'emploi ici et commencez votre carrière chez RONA inc., et Rona"
            : "Looking to start a new chapter with RONA inc.? Search our openings here and start your career with RONA inc., and Rona"
        }
        hidden={false}
        lang={process.env.LANG ? process.env.LANG : "en"}
        lang_link={isFrench ? `${EN_URL}/` : `${FR_URL}/`}
        bodyClasses={`page-index${isInternal ? " page-index-internal" : ""}`}
      />
      <main id={"main"}>
        <section>
          <div
            className={"maxWidth pt0-lg"}
            style={{
              paddingTop: "96px",
              paddingBottom: "96px",
            }}
          >
            <h1 className={"text-center"} style={{ fontSize: "24px" }}>
              <span className={""} style={{ fontSize: "96px" }}>
                404
              </span>{" "}
              <br /> {isFrench ? `Page Non Trouvée.` : `Page Not Found.`}
            </h1>
            <a
              href="/"
              className={"my2-ml mx3-ml chip chip-blue"}
              style={{
                padding: "0.6rem 2rem",
                borderRadius: "5px",
                fontSize: "20px",
                fontWeight: "normal",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                whiteSpace: "nowrap",
                fontWeight: "bold",
                background: "#00539c",
                color: "#ffffff",
                width: "fit-content",
                margin: "auto",
              }}
            >
              {isFrench ? "Retourner" : "Go Back"}
            </a>
          </div>
        </section>
      </main>
      <Footer lang={process.env.LANG ? process.env.LANG : "en"} />
    </>
  );
};

export default NotFoundPage;
